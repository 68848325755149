import React from 'react';
import { Layout } from 'antd';
import { Routes, Route } from 'react-router-dom';
import UserList from './UserList';
import EditUserRole from './EditUserRole';
import NewsList from './NewsList';
import AddNewsForm from './AddNews';
import EditNews from './EditNews';

const { Content } = Layout;

const AppContent = ({ isDarkTheme, selectedKey, setSelectedKey }) => {
  const renderContent = () => {
    switch (selectedKey) {
      case '2':
        return <UserList />;
      case '3':
        return <EditUserRole />;
      case '4':
        return <NewsList setSelectedKey={setSelectedKey} />;
      case '5':
        return <AddNewsForm />;
      case '6':
        return <EditNews />;
      case '1':
      default:
        return <div>Главная контент</div>;
    }
  };

  return (
    <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
      <div
        style={{
          padding: '24px',
          background: isDarkTheme ? '#141414' : '#fff',
          color: isDarkTheme ? '#fff' : '#000',
          minHeight: '360px',
          borderRadius: '8px',
          boxShadow: isDarkTheme ? '0 0 10px rgba(255, 255, 255, 0.1)' : '0 0 10px rgba(0, 0, 0, 0.1)',
          transition: 'background 0.3s, color 0.3s',
        }}
      >
        <Routes>
          <Route path="/" element={renderContent()} />
        </Routes>
      </div>
    </Content>
  );
};

export default AppContent;