import React, { useEffect, useState } from 'react';
import { Table, Button, Popconfirm, message, Space, Card } from 'antd';
import { DeleteOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const GET_JOURNALS = gql`
  query GetJournals {
    journals {
      id
      title
      period
      pdfUrl
    }
  }
`;

const DELETE_JOURNAL = gql`
  mutation DeleteJournal($id: ID!) {
    deleteJournal(id: $id) {
      id
    }
  }
`;

const ViewJournals = () => {
  const [journals, setJournals] = useState([]);
  const { data, loading } = useQuery(GET_JOURNALS);
  const [deleteJournal] = useMutation(DELETE_JOURNAL);

  useEffect(() => {
    if (data && data.journals) {
      setJournals(data.journals);
    }
  }, [data]);

  const handleDelete = async (id) => {
    try {
      await deleteJournal({ variables: { id } });
      setJournals(journals.filter(journal => journal.id !== id));
      message.success('Журнал удален');
    } catch (error) {
      message.error('Ошибка при удалении');
    }
  };

  const columns = [
    { title: 'Название', dataIndex: 'title', key: 'title' },
    { title: 'Период', dataIndex: 'period', key: 'period' },
    {
      title: 'Действия',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button type="primary" icon={<EyeOutlined />} href={record.pdfUrl} target="_blank">Посмотреть</Button>
          <Button type="default" icon={<DownloadOutlined />} href={record.pdfUrl} download>Скачать</Button>
          <Popconfirm title="Удалить журнал?" onConfirm={() => handleDelete(record.id)}>
            <Button type="primary" danger icon={<DeleteOutlined />}>Удалить</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Card title="Журналы" style={{ margin: 20, borderRadius: 8, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
      <Table dataSource={journals} columns={columns} rowKey="id" loading={loading} bordered />
    </Card>
  );
};

export default ViewJournals;