import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Table, Select, Button, Spin, notification } from 'antd';

const GET_USERS = gql`
  query {
    users {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`;

const GET_ROLES = gql`
  query {
    roles {
      id
      name
    }
  }
`;

const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($userId: ID!, $roleId: ID!) {
    updateUserRole(userId: $userId, roleId: $roleId) {
      id
      roles {
        id
        name
      }
    }
  }
`;

const EditUserRole = () => {
  const { loading: usersLoading, error: usersError, data: usersData } = useQuery(GET_USERS);
  const { loading: rolesLoading, error: rolesError, data: rolesData } = useQuery(GET_ROLES);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [selectedRoles, setSelectedRoles] = useState({});

  if (usersLoading || rolesLoading) return <Spin />;
  if (usersError) return <p>Ошибка пользователей: {usersError.message}</p>;
  if (rolesError) return <p>Ошибка ролей: {rolesError.message}</p>;

  const handleRoleChange = (userId, roleId) => {
    setSelectedRoles((prev) => ({
      ...prev,
      [userId]: roleId,
    }));
  };

  const handleUpdateRole = async (userId) => {
    const roleId = selectedRoles[userId];
    try {
      await updateUserRole({ variables: { userId, roleId } });
      notification.success({ message: 'Роль успешно обновлена!' });
    } catch (error) {
      notification.error({ message: 'Ошибка при обновлении роли.' });
    }
  };

  const columns = [
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Роль',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles, record) => (
        <Select
          defaultValue={roles[0]?.id}
          onChange={(value) => handleRoleChange(record.id, value)}
          style={{ width: 200 }}
        >
          {rolesData.roles.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Действия',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleUpdateRole(record.id)} type="primary">
          Обновить
        </Button>
      ),
    },
  ];

  return <Table dataSource={usersData.users} columns={columns} rowKey="id" />;
};

export default EditUserRole;