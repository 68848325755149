import React from 'react';
import { createRoot } from 'react-dom/client'; // Импортируйте createRoot
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import App from './App';
import 'antd/dist/reset.css'; 
import client from './api'; // Импортируйте client из api.js

// Получите элемент DOM
const container = document.getElementById('root');
// Создайте корень приложения
const root = createRoot(container);
// Используйте метод render для рендеринга приложения
root.render(
  <ApolloProvider client={client}>
    <Router>
      <App />
    </Router>
  </ApolloProvider>
);