import React, { useState } from 'react';

import { Form, Input, Button, Card, Typography, Alert, Checkbox, Radio } from 'antd';

import { registerUser } from '../api'; // Функция для отправки данных регистрации на сервер

import { useNavigate } from 'react-router-dom';

 

const { Title } = Typography;

 

const Register = () => {

  // Определяем этап регистрации: 1 — базовые данные, 2 — выбор типа профиля и доп. данные

  const [currentStep, setCurrentStep] = useState(1);

  // Сохраним данные с первого этапа

  const [basicData, setBasicData] = useState({});

  const [errorMessage, setErrorMessage] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  const navigate = useNavigate();

 

  const [formStep1] = Form.useForm();

  const [formStep2] = Form.useForm();

 

  // Обработка данных первого этапа

  const onFinishStep1 = (values) => {

    setBasicData(values);

    setCurrentStep(2);

  };

 

  // Обработка данных второго этапа — объединяем данные с обоих этапов и отправляем запрос

  const onFinishStep2 = async (values) => {

    const registrationData = { ...basicData, ...values };

    try {

      const data = await registerUser(registrationData);

      if (data.success) {

        setSuccessMessage('Регистрация прошла успешно! Вы можете войти в систему.');

        setErrorMessage('');

        setTimeout(() => {

          navigate('/login');

        }, 1500);

      } else {

        setErrorMessage(data.message || 'Ошибка регистрации. Попробуйте снова.');

        setSuccessMessage('');

      }

    } catch (error) {

      setErrorMessage('Ошибка сервера. Пожалуйста, попробуйте позже.');

      setSuccessMessage('');

      console.error(error);

    }

  };

 

  const goBack = () => {

    setCurrentStep(1);

  };

 

  return (

    <div

      style={{

        display: 'flex',

        justifyContent: 'center',

        alignItems: 'center',

        minHeight: '100vh',

        backgroundColor: '#f0f2f5'

      }}

    >

      <Card style={{ width: 500, padding: '20px' }}>

        <Title level={3} style={{ textAlign: 'center' }}>Регистрация</Title>

        {successMessage && (

          <Alert

            message={successMessage}

            type="success"

            showIcon

            style={{ marginBottom: 16 }}

          />

        )}

        {errorMessage && (

          <Alert

            message={errorMessage}

            type="error"

            showIcon

            style={{ marginBottom: 16 }}

          />

        )}

 

        {currentStep === 1 && (

          <Form

            form={formStep1}

            layout="vertical"

            onFinish={onFinishStep1}

          >

            <Form.Item

              label="Имя"

              name="firstName"

              rules={[{ required: true, message: 'Введите имя' }]}

            >

              <Input placeholder="Введите имя" />

            </Form.Item>

            <Form.Item

              label="Фамилия"

              name="lastName"

              rules={[{ required: true, message: 'Введите фамилию' }]}

            >

              <Input placeholder="Введите фамилию" />

            </Form.Item>

            <Form.Item

              label="Отчество"

              name="patronymic"

            >

              <Input placeholder="Введите отчество (если есть)" />

            </Form.Item>

            <Form.Item

              label="Электронная почта"

              name="email"

              rules={[

                { required: true, message: 'Введите email' },

                { type: 'email', message: 'Неверный формат email' }

              ]}

            >

              <Input placeholder="Введите email" />

            </Form.Item>

            <Form.Item

              label="Пароль"

              name="password"

              rules={[{ required: true, message: 'Введите пароль' }]}

            >

              <Input.Password placeholder="Введите пароль" />

            </Form.Item>

            <Form.Item

              label="Номер телефона"

              name="phone"

              rules={[{ required: true, message: 'Введите номер телефона' }]}

            >

              <Input placeholder="Введите номер телефона" />

            </Form.Item>

            <Form.Item

              name="consent"

              valuePropName="checked"

              rules={[{ required: true, message: 'Подтвердите согласие' }]}

            >

              <Checkbox>

                Я даю согласие на обработку персональных данных

              </Checkbox>

            </Form.Item>

 

            <Title level={5}>Адрес</Title>

            <Form.Item

              label="Почтовый индекс"

              name={['address', 'index']}

              rules={[{ required: true, message: 'Введите почтовый индекс' }]}

            >

              <Input placeholder="Введите почтовый индекс" />

            </Form.Item>

            <Form.Item

              label="Населенный пункт"

              name={['address', 'locality']}

              rules={[{ required: true, message: 'Введите населенный пункт' }]}

            >

              <Input placeholder="Введите населенный пункт" />

            </Form.Item>

            <Form.Item

              label="Улица"

              name={['address', 'street']}

            >

              <Input placeholder="Введите улицу" />

            </Form.Item>

            <Form.Item

              label="Дом"

              name={['address', 'house']}

              rules={[{ required: true, message: 'Введите номер дома' }]}

            >

              <Input placeholder="Введите номер дома" />

            </Form.Item>

            <Form.Item

              label="Корпус"

              name={['address', 'building']}

            >

              <Input placeholder="Введите корпус (если есть)" />

            </Form.Item>

            <Form.Item

              label="Квартира"

              name={['address', 'apartment']}

            >

              <Input placeholder="Введите квартиру (если есть)" />

            </Form.Item>

            <Form.Item>

              <Button type="primary" htmlType="submit" block>

                Далее

              </Button>

            </Form.Item>

          </Form>

        )}

 

        {currentStep === 2 && (

          <Form

            form={formStep2}

            layout="vertical"

            onFinish={onFinishStep2}

          >

            <Form.Item

              label="Тип профиля"

              name="profileType"

              rules={[{ required: true, message: 'Выберите тип профиля' }]}

            >

              <Radio.Group>

                <Radio value="USER">Пользователь</Radio>

                <Radio value="CHAIRMAN">Председатель</Radio>

                <Radio value="VILLAGE_HEAD">Староста</Radio>

                <Radio value="STUDENT">Студент</Radio>

              </Radio.Group>

            </Form.Item>

 

            {/* Динамически отображаем дополнительные поля в зависимости от выбранного типа профиля */}

            <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.profileType !== curValues.profileType}>

              {({ getFieldValue }) => {

                const profileType = getFieldValue('profileType');

                if (profileType === 'CHAIRMAN') {

                  return (

                    <>

                      <Form.Item

                        label="Наименование организации"

                        name={['chairmanProfile', 'tosName']}

                        rules={[{ required: true, message: 'Введите наименование организации' }]}

                      >

                        <Input placeholder="Введите наименование организации" />

                      </Form.Item>

                      <Form.Item

                        name={['chairmanProfile', 'isLegalEntity']}

                        valuePropName="checked"

                        rules={[{ required: true, message: 'Укажите статус юридического лица' }]}

                      >

                        <Checkbox>Является юридическим лицом</Checkbox>

                      </Form.Item>

                      <Form.Item

                        label="ОГРН"

                        name={['chairmanProfile', 'ogrn']}

                      >

                        <Input placeholder="Введите ОГРН" />

                      </Form.Item>

                      <Form.Item

                        label="ИНН"

                        name={['chairmanProfile', 'inn']}

                      >

                        <Input placeholder="Введите ИНН" />

                      </Form.Item>

                      <Form.Item

                        label="КПП"

                        name={['chairmanProfile', 'kpp']}

                      >

                        <Input placeholder="Введите КПП" />

                      </Form.Item>

                      <Form.Item

                        label="Дата регистрации"

                        name={['chairmanProfile', 'registrationDate']}

                      >

                        <Input placeholder="Введите дату регистрации" />

                      </Form.Item>

                      <Form.Item

                        label="Дата выборов"

                        name={['chairmanProfile', 'electionDate']}

                      >

                        <Input placeholder="Введите дату выборов" />

                      </Form.Item>

                      <Form.Item

                        label="Срок полномочий"

                        name={['chairmanProfile', 'termDuration']}

                      >

                        <Input placeholder="Введите срок полномочий" />

                      </Form.Item>

                      <Form.Item

                        label="Социальный статус"

                        name={['chairmanProfile', 'socialStatus']}

                      >

                        <Input placeholder="Введите социальный статус" />

                      </Form.Item>

                      <Form.Item

                        label="Уровень образования"

                        name={['chairmanProfile', 'educationLevel']}

                      >

                        <Input placeholder="Введите уровень образования" />

                      </Form.Item>

                      <Form.Item

                        name={['chairmanProfile', 'publicServiceExperience']}

                        valuePropName="checked"

                      >

                        <Checkbox>Опыт работы в гос.службе</Checkbox>

                      </Form.Item>

                      <Form.Item

                        label="Скан документа"

                        name={['chairmanProfile', 'documentScan']}

                      >

                        <Input placeholder="Ссылка или имя файла" />

                      </Form.Item>

                      <Form.Item

                        label="Количество жителей"

                        name={['chairmanProfile', 'residentCount']}

                      >

                        <Input placeholder="Введите количество жителей" />

                      </Form.Item>

                      <Form.Item

                        label="Активное количество жителей"

                        name={['chairmanProfile', 'activeResidentCount']}

                      >

                        <Input placeholder="Введите активное количество жителей" />

                      </Form.Item>

                    </>

                  );

                } else if (profileType === 'VILLAGE_HEAD') {

                  return (

                    <>

                      <Form.Item

                        label="Название населенного пункта"

                        name={['villageHeadProfile', 'localityName']}

                        rules={[{ required: true, message: 'Введите название населенного пункта' }]}

                      >

                        <Input placeholder="Введите название населенного пункта" />

                      </Form.Item>

                      <Form.Item

                        label="Дата назначения"

                        name={['villageHeadProfile', 'appointmentDate']}

                        rules={[{ required: true, message: 'Введите дату назначения' }]}

                      >

                        <Input placeholder="Введите дату назначения" />

                      </Form.Item>

                      <Form.Item

                        label="Срок полномочий"

                        name={['villageHeadProfile', 'termDuration']}

                      >

                        <Input placeholder="Введите срок полномочий" />

                      </Form.Item>

                      <Form.Item

                        label="Социальный статус"

                        name={['villageHeadProfile', 'socialStatus']}

                      >

                        <Input placeholder="Введите социальный статус" />

                      </Form.Item>

                      <Form.Item

                        label="Уровень образования"

                        name={['villageHeadProfile', 'educationLevel']}

                      >

                        <Input placeholder="Введите уровень образования" />

                      </Form.Item>

                      <Form.Item

                        name={['villageHeadProfile', 'publicServiceExperience']}

                        valuePropName="checked"

                      >

                        <Checkbox>Опыт работы в гос.службе</Checkbox>

                      </Form.Item>

                      <Form.Item

                        label="Скан документа"

                        name={['villageHeadProfile', 'documentScan']}

                      >

                        <Input placeholder="Ссылка или имя файла" />

                      </Form.Item>

                      <Form.Item

                        label="Количество жителей"

                        name={['villageHeadProfile', 'residentCount']}

                      >

                        <Input placeholder="Введите количество жителей" />

                      </Form.Item>

                      <Form.Item

                        label="Активное количество жителей"

                        name={['villageHeadProfile', 'activeResidentCount']}

                      >

                        <Input placeholder="Введите активное количество жителей" />

                      </Form.Item>

                    </>

                  );

                } else if (profileType === 'STUDENT') {

                  return (

                    <>

                      <Form.Item

                        label="Уровень образования"

                        name={['studentProfile', 'educationLevel']}

                        rules={[{ required: true, message: 'Введите уровень образования' }]}

                      >

                        <Input placeholder="Введите уровень образования" />

                      </Form.Item>

                      <Form.Item

                        label="Скан диплома"

                        name={['studentProfile', 'diplomaScan']}

                        rules={[{ required: true, message: 'Введите ссылку или имя файла скана диплома' }]}

                      >

                        <Input placeholder="Введите скан диплома" />

                      </Form.Item>

                      <Form.Item

                        label="Серия паспорта"

                        name={['studentProfile', 'passportSeries']}

                        rules={[{ required: true, message: 'Введите серию паспорта' }]}

                      >

                        <Input placeholder="Введите серию паспорта" />

                      </Form.Item>

                      <Form.Item

                        label="Номер паспорта"

                        name={['studentProfile', 'passportNumber']}

                        rules={[{ required: true, message: 'Введите номер паспорта' }]}

                      >

                        <Input placeholder="Введите номер паспорта" />

                      </Form.Item>

                      <Form.Item

                        label="Кем выдан паспорт"

                        name={['studentProfile', 'passportIssuedBy']}

                        rules={[{ required: true, message: 'Введите, кем выдан паспорт' }]}

                      >

                        <Input placeholder="Введите, кем выдан паспорт" />

                      </Form.Item>

                      <Form.Item

                        label="Код подразделения"

                        name={['studentProfile', 'passportCode']}

                        rules={[{ required: true, message: 'Введите код подразделения' }]}

                      >

                        <Input placeholder="Введите код подразделения" />

                      </Form.Item>

                      <Form.Item

                        label="Дата выдачи паспорта"

                        name={['studentProfile', 'passportIssueDate']}

                        rules={[{ required: true, message: 'Введите дату выдачи паспорта' }]}

                      >

                        <Input placeholder="Введите дату выдачи паспорта" />

                      </Form.Item>

                      <Form.Item

                        label="СНИЛС"

                        name={['studentProfile', 'snils']}

                        rules={[{ required: true, message: 'Введите СНИЛС' }]}

                      >

                        <Input placeholder="Введите СНИЛС" />

                      </Form.Item>

                      <Form.Item

                        label="Скан СНИЛС"

                        name={['studentProfile', 'snilsScan']}

                        rules={[{ required: true, message: 'Введите ссылку или имя файла скана СНИЛС' }]}

                      >

                        <Input placeholder="Введите скан СНИЛС" />

                      </Form.Item>

                      <Form.Item

                        label="ИНН"

                        name={['studentProfile', 'inn']}

                      >

                        <Input placeholder="Введите ИНН (если есть)" />

                      </Form.Item>

                    </>

                  );

                } else if (profileType === 'USER') {

                  return (

                    <>

                      <Form.Item

                        label="Дополнительная информация"

                        name={['userProfile', 'placeholder']}

                      >

                        <Input placeholder="Введите дополнительную информацию (необязательно)" />

                      </Form.Item>

                    </>

                  );

                }

                return null;

              }}

            </Form.Item>

            <Form.Item>

              <Button onClick={goBack} style={{ marginRight: 8 }}>

                Назад

              </Button>

              <Button type="primary" htmlType="submit">

                Зарегистрироваться

              </Button>

            </Form.Item>

          </Form>

        )}

      </Card>

    </div>

  );

};

 


export default Register;