import React from 'react';
import { Card, Button, Popconfirm, notification, Col, Row, Typography } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const GET_NEWS_LIST = gql`
  query {
    newsList {
      id
      title
      publishDate
      imageUrl
    }
  }
`;

const DELETE_NEWS = gql`
  mutation deleteNews($id: String!) {
    deleteNews(id: $id) {
      id
    }
  }
`;

const NewsList = ({ setSelectedKey }) => {
  const { loading, error, data, refetch } = useQuery(GET_NEWS_LIST);
  const [deleteNews] = useMutation(DELETE_NEWS);
  const navigate = useNavigate();

  if (loading) return <p>Загрузка...</p>;
  if (error) return <p>Ошибка: {error.message}</p>;

  const handleEdit = (id) => {
    navigate(`/news/edit/${id}`); // Переход на страницу редактирования
  };

  const handleDelete = async (id) => {
    try {
      await deleteNews({ variables: { id } });
      notification.success({
        message: 'Успешно',
        description: 'Новость успешно удалена.',
      });
      refetch(); // Обновляем список новостей после удаления
    } catch (error) {
      console.error('Ошибка при удалении новости:', error);
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось удалить новость. Попробуйте еще раз.',
      });
    }
  };

  return (
    <Row gutter={[16, 16]}>
      {data.newsList.map((news) => (
        <Col xs={24} sm={12} md={8} key={news.id}>
          <Card
            hoverable
            style={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            {news.imageUrl && (
              <img
                alt={news.title}
                src={news.imageUrl}
                style={{
                  width: '100%',
                  height: '150px',
                  objectFit: 'cover',
                  borderRadius: '8px 8px 0 0',
                }}
              />
            )}
            <div style={{ padding: '16px' }}>
              <Title level={5} style={{ margin: 0 }}>
                {news.title}
              </Title>
              <p style={{ margin: '8px 0', color: '#888' }}>
                {new Date(news.publishDate).toLocaleString()}
              </p>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                <Button type="primary" onClick={() => handleEdit(news.id)}>
                  Редактировать
                </Button>
                <Popconfirm
                  title="Вы уверены, что хотите удалить эту новость?"
                  onConfirm={() => handleDelete(news.id)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button type="danger">Удалить</Button>
                </Popconfirm>
              </div>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default NewsList;