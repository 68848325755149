import React from 'react';
import { Layout, Menu, Button } from 'antd';
import {
  UserOutlined,
  DashboardOutlined,
  SettingOutlined,
  FileAddOutlined,
  ReadOutlined,
  PlusCircleOutlined,
  DownOutlined,
  UploadOutlined,
  EyeOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Sidebar = ({ setSelectedKey, collapsed, onCollapse, selectedKey, userRole, userId }) => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  const menuItems = [
    { key: '1', icon: <DashboardOutlined />, label: 'Главная', link: '/dashboard' },
    {
      key: '2',
      icon: <UserOutlined />,
      label: 'Пользователи',
      link: '/dashboard/users',
      roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'],
    },
    {
      key: '3',
      icon: <SettingOutlined />,
      label: 'Редактировать роли',
      link: '/dashboard/edit-roles',
      roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'],
    },
    { key: '4', icon: <FileAddOutlined />, label: 'Новости', link: '/dashboard/news', roles: ['NEWS EDITOR', 'SYSTEM ADMINISTRATOR'] },
    {
      key: '5',
      icon: <FileAddOutlined />,
      label: 'Добавить новость',
      link: '/dashboard/add-news',
      roles: ['NEWS EDITOR', 'SYSTEM ADMINISTRATOR'],
    },
    {
      key: '6',
      icon: <PlusCircleOutlined />,
      label: 'Создать курс',
      link: '/dashboard/create-course',
      roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'],
    },
    { key: '7', icon: <ReadOutlined />, label: 'Курсы', link: '/dashboard/courses', roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'] },
    {
      key: '8',
      icon: <ReadOutlined />,
      label: 'Мои курсы',
      link: `/dashboard/my-courses/${userId}`,
      roles: ['STAFF'],
    },
    {
      key: '9',
      icon: <UserOutlined />,
      label: 'Результаты тестов',
      link: '/dashboard/test-results',
      roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'],
    },
    {
      key: '10',
      icon: <DownOutlined />,
      label: 'Журнал муниципального актива',
      children: [
        { key: '10-1', icon: <UploadOutlined />, label: 'Загрузить', link: '/dashboard/journals/upload' },
        { key: '10-2', icon: <EyeOutlined />, label: 'Посмотреть', link: '/dashboard/journals/view' },
      ],
      roles: ['COURSE MANAGER', 'SYSTEM ADMINISTRATOR'],
    },
  ];

  const filteredMenuItems = menuItems.filter(
    (item) => !item.roles || item.roles.includes(userRole)
  );

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth={80}
      style={{
        background: '#fff',
        color: '#000',
        boxShadow: '2px 0 6px rgba(0, 21, 41, 0.12)',
      }}
    >
      <div
        style={{ padding: '16px', textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}
      >
        Панель управления
      </div>
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={[selectedKey]}
        onSelect={({ key }) => setSelectedKey(key)}
        style={{ background: '#ffffff', color: '#000' }}
      >
        {filteredMenuItems.map((item) =>
          item.children ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((subItem) => (
                <Menu.Item key={subItem.key} icon={subItem.icon}>
                  <Link to={subItem.link}>{subItem.label}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.link}>{item.label}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Button type="primary" danger onClick={handleLogout} style={{ width: '100%' }}>
          Выход
        </Button>
      </div>
    </Sider>
  );
};

export default Sidebar;