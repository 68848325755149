import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, Alert } from 'antd';
import { registerUser } from '../api';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const onFinish = async () => {
    try {
      const data = await registerUser(name, email, password);
      if (data.success) {
        setSuccessMessage('Регистрация прошла успешно! Вы можете войти в систему.');
        setErrorMessage('');
        setTimeout(() => {
          navigate('/login'); // Перенаправление на страницу входа после регистрации
        }, 1000);
      } else {
        setErrorMessage(data.message || 'Ошибка регистрации. Попробуйте снова.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Ошибка сервера. Пожалуйста, попробуйте позже.');
      setSuccessMessage('');
      console.error(error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <Card bordered={false} style={{ width: 400, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', borderRadius: 0 }}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'normal', color: '#333' }}>Регистрация</Title>
        {successMessage && (
          <Alert 
            message={successMessage} 
            type="success" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        {errorMessage && (
          <Alert 
            message={errorMessage} 
            type="error" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Имя"
            name="name"
            rules={[{ required: true, message: 'Пожалуйста, введите ваше имя!' }]}
          >
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Введите ваше имя"
            />
          </Form.Item>
          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, введите вашу электронную почту!' }]}
          >
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите вашу электронную почту"
            />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}
          >
            <Input.Password
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите ваш пароль"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#1d1f21', borderColor: '#1d1f21' }}>
              Зарегистрироваться
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Register;