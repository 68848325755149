import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, Alert, Divider, Checkbox } from 'antd';
import { loginUser } from '../api'; 
import { useNavigate, Link } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const Login = () => {
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { email, password } = values;
      const data = await loginUser(email, password);
      if (data.token) {
        localStorage.setItem('token', data.token);
        setSuccessMessage(data.message || 'Вход выполнен успешно!');
        setErrorMessage('');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      } else {
        setErrorMessage(data.message || 'Неверные учетные данные. Попробуйте снова.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || 'Ошибка сервера. Пожалуйста, попробуйте позже.');
      setSuccessMessage('');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#f9f9f9',
      backgroundImage: 'url(/path-to-your-light-background.jpg)', // Легкое фоновое изображение
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
      <Card 
        bordered={false} 
        style={{ 
          width: 400, 
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', 
          borderRadius: '12px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)', // Прозрачность для свежести
          padding: '24px'
        }}
      >
        <Title level={3} style={{ textAlign: 'center', marginBottom: 20, fontWeight: '500', color: '#333' }}>
          Вход в систему
        </Title>
        {successMessage && (
          <Alert 
            message={successMessage} 
            type="success" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        {errorMessage && (
          <Alert 
            message={errorMessage} 
            type="error" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, введите вашу электронную почту!' }]}
          >
            <Input
              prefix={<UserOutlined style={{ color: '#333' }} />}
              placeholder="Введите вашу электронную почту"
              allowClear
              style={{ borderRadius: '4px' }}
            />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#333' }} />}
              placeholder="Введите ваш пароль"
              allowClear
              style={{ borderRadius: '4px' }}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              block 
              loading={loading}
              disabled={loading}
              style={{ 
                backgroundColor: '#333', 
                borderColor: '#333', 
                borderRadius: '4px',
                fontWeight: '500'
              }}
            >
              Войти
            </Button>
          </Form.Item>
          <Divider style={{ margin: '16px 0' }} />
          <Form.Item style={{ textAlign: 'center' }}>
            <Text style={{ color: '#333' }}>
              Нет аккаунта? <Link to="/register" style={{ color: '#333', fontWeight: '500' }}>Зарегистрируйтесь</Link>
            </Text>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <Link to="/forgot-password" style={{ color: '#333', fontWeight: '500' }}>Забыли пароль?</Link>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;
