import React, { useState } from 'react';
import { Upload, Button, Form, Input, message, Modal, Spin, Card, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

const { Title } = Typography;

const GET_CURRENT_USER = gql`
  query {
    currentUser {
      id
    }
  }
`;

const UPLOAD_JOURNAL_FILE = gql`
  mutation UploadJournalFile($file: Upload!, $type: String!) {
    uploadJournalFile(file: $file, type: $type)
  }
`;

const CREATE_JOURNAL = gql`
  mutation CreateJournal(
    $title: String!
    $period: String
    $months: [String]
    $isPaid: Boolean
    $creatorId: ID!
    $cover: String
    $pdfFile: String
  ) {
    createJournal(
      title: $title
      period: $period
      months: $months
      isPaid: $isPaid
      creatorId: $creatorId
      cover: $cover
      pdfFile: $pdfFile
    ) {
      id
      title
      period
      coverUrl
      pdfUrl
    }
  }
`;

const UploadJournal = () => {
  const [form] = Form.useForm();
  const [coverId, setCoverId] = useState(null);
  const [pdfId, setPdfId] = useState(null);
  const [uploading, setUploading] = useState({ cover: false, pdf: false });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [creatorId, setCreatorId] = useState(null);

  const [uploadFile] = useMutation(UPLOAD_JOURNAL_FILE);
  const [createJournal] = useMutation(CREATE_JOURNAL);

  const { loading: userLoading, error: userError } = useQuery(GET_CURRENT_USER, {
    onCompleted: (data) => {
      setCreatorId(data?.currentUser?.id);
    }
  });

  const handleFileUpload = async (file, type) => {
    setUploading(prev => ({ ...prev, [type]: true }));
    
    try {
      const { data } = await uploadFile({
        variables: { file, type },
        context: { hasUpload: true }
      });
      
      if (type === 'cover') {
        setCoverId(data.uploadJournalFile);
      } else {
        setPdfId(data.uploadJournalFile);
      }
      message.success('Файл успешно загружен');
    } catch (error) {
      message.error(`Ошибка загрузки: ${error.message}`);
    } finally {
      setUploading(prev => ({ ...prev, [type]: false }));
    }
  };

  const handlePreview = async file => {
    if (file.url) {
      setPreviewImage(file.url);
      setPreviewVisible(true);
    }
  };

  const customUploadRequest = async ({ file, onSuccess, onError, type }) => {
    try {
      await handleFileUpload(file, type);
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  const handleSubmit = async values => {
    try {
      if (!coverId || !pdfId) {
        throw new Error('Необходимо загрузить оба файла!');
      }

      if (!creatorId) {
        throw new Error('Пользователь не авторизован!');
      }

      await createJournal({
        variables: {
          ...values,
          creatorId: creatorId,
          cover: coverId,
          pdfFile: pdfId
        }
      });

      message.success('Журнал успешно создан!');
      form.resetFields();
      setCoverId(null);
      setPdfId(null);
    } catch (error) {
      message.error(error.message);
    }
  };

  if (userLoading) return <Spin size="large" />;
  if (userError) return <div>Ошибка авторизации!</div>;

  return (
    <Card
      style={{
        margin: 20,
        padding: 20,
        borderRadius: 8,
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        background: '#fff'
      }}
    >
      <Title level={3} style={{ textAlign: 'center', marginBottom: 20 }}>
        Создать журнал
      </Title>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Название"
          rules={[{ required: true, message: 'Введите название' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="period"
          label="Период"
          rules={[{ required: true, message: 'Введите период' }]}
        >
          <Input placeholder="Например, 2025" />
        </Form.Item>

        <Form.Item
          name="cover"
          label="Обложка"
          extra="Загружается сразу при выборе"
        >
          <Upload
            customRequest={({ file, onSuccess, onError }) => 
              customUploadRequest({ file, onSuccess, onError, type: 'cover' })
            }
            listType="picture-card"
            onPreview={handlePreview}
            showUploadList={{ showRemoveIcon: false }}
            accept="image/*"
          >
            {uploading.cover ? (
              <Spin />
            ) : (
              <div>
                <UploadOutlined />
                <div>Загрузить обложку</div>
              </div>
            )}
          </Upload>
        </Form.Item>

        <Form.Item
          name="pdfFile"
          label="PDF файл"
          extra="Загружается сразу при выборе"
        >
          <Upload
            customRequest={({ file, onSuccess, onError }) => 
              customUploadRequest({ file, onSuccess, onError, type: 'pdf' })
            }
            showUploadList={false}
            accept=".pdf"
          >
            <Button 
              icon={<UploadOutlined />} 
              loading={uploading.pdf}
            >
              {uploading.pdf ? 'Загрузка...' : 'Выбрать PDF'}
            </Button>
          </Upload>
          {pdfId && <div style={{ marginTop: 8 }}>Файл загружен</div>}
        </Form.Item>

        <Button type="primary" htmlType="submit">
          Создать журнал
        </Button>
      </Form>

      <Modal
        visible={previewVisible}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </Card>
  );
};

export default UploadJournal;
