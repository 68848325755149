import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import PasswordRecovery from './components/PasswordRecovery';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute'; // Импортируйте компонент PrivateRoute
import NewsList from './components/dashboard/NewsList';
import EditNews from './components/dashboard/EditNews'; // Импортируйте компонент редактирования

const App = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/recover-password" element={<PasswordRecovery />} />
      <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} /> {/* Защита маршрута */}
      <Route path="/news" element={<NewsList />} />
      <Route path="/news/edit/:id" element={<EditNews />} /> {/* Добавьте этот маршрут */}
    </Routes>
  );
};

export default App;