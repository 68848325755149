import React, { useState } from 'react';
import { Layout } from 'antd';
import { AppHeader, Sidebar, AppContent } from './dashboard';

const Dashboard = () => {
  const [selectedKey, setSelectedKey] = useState('1');
  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh', background: '#ffffff' }}>
      <Sidebar
        setSelectedKey={setSelectedKey}
        collapsed={collapsed}
        onCollapse={handleCollapse}
      />
      <Layout>
        <AppHeader />
        <AppContent selectedKey={selectedKey} />
      </Layout>
    </Layout>
  );
};

export default Dashboard;