import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const token = localStorage.getItem('token');

  // Если токена нет, перенаправляем на страницу входа
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  // Если токен есть, рендерим элемент
  return element;
};

export default PrivateRoute;