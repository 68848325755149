import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography, Alert } from 'antd';
import { loginUser } from '../api'; 
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const onFinish = async () => {
    try {
      const data = await loginUser(email, password);
      if (data.token) {
        localStorage.setItem('token', data.token);
        setSuccessMessage(data.message || 'Вход выполнен успешно!');
        setErrorMessage('');
        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      } else {
        setErrorMessage(data.message || 'Неверные учетные данные. Попробуйте снова.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || 'Ошибка сервера. Пожалуйста, попробуйте позже.');
      setSuccessMessage('');
      console.error(error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <Card bordered={false} style={{ width: 400, boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', borderRadius: 0 }}>
        <Title level={3} style={{ textAlign: 'center', marginBottom: 20, fontWeight: 'normal', color: '#333' }}>Вход в систему</Title>
        {successMessage && (
          <Alert 
            message={successMessage} 
            type="success" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        {errorMessage && (
          <Alert 
            message={errorMessage} 
            type="error" 
            showIcon 
            style={{ marginBottom: 16 }} 
          />
        )}
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Электронная почта"
            name="email"
            rules={[{ required: true, message: 'Пожалуйста, введите вашу электронную почту!' }]}
          >
            <Input
              prefix={<UserOutlined />}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Введите вашу электронную почту"
              allowClear
            />
          </Form.Item>
          <Form.Item
            label="Пароль"
            name="password"
            rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Введите ваш пароль"
              allowClear
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" block style={{ backgroundColor: '#1d1f21', borderColor: '#1d1f21' }}>
              Войти
            </Button>
          </Form.Item>
          <Form.Item style={{ textAlign: 'center' }}>
            <a href="/forgot-password" style={{ color: '#1d1f21' }}>Забыли пароль?</a>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;