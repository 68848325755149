import { gql } from '@apollo/client';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

// Определение мутаций
export const REGISTER_USER = gql`
  mutation Register($name: String!, $email: String!, $password: String!) {
    register(name: $name, email: $email, password: $password) {
      success
      message
      user {
        id
        name
        email
      }
    }
  }
`;

const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      message
      token
      user {
        id
        name
        email
      }
    }
  }
`;


// Функция для регистрации пользователя
export const registerUser = async (name, email, password) => {
  try {
    const { data } = await client.mutate({
      mutation: REGISTER_USER,
      variables: { name, email, password },
    });
    console.log("User registered successfully:", data.register);
    return data.register;
  } catch (error) {
    console.error("Error during user registration:", error);
    throw error; // Пробрасываем ошибку дальше для обработки
  }
};

// Функция для входа пользователя
export const loginUser = async (email, password) => {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN_USER,
      variables: { email, password },
    });
    return data.login;
  } catch (error) {
    console.error('Ошибка входа:', error);
    throw error; // Перебрасываем ошибку для обработки в компоненте
  }
};

// Создайте ссылку для загрузки файлов
const uploadLink = createUploadLink({
  uri: 'https://lk.oatos.ru/graphql', // Замените на ваш URL
  credentials: 'include', // Если нужно включить cookies
});

// Создайте ссылку для добавления токена аутентификации
const authLink = new ApolloLink((operation, forward) => {
  // Получаем токен из localStorage
  const token = localStorage.getItem('token');
  // Добавляем токен в заголовки запроса
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

// Создайте Apollo Client
const client = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]), // Используйте authLink перед uploadLink
  cache: new InMemoryCache(),
});

export default client; // Экспортируйте client