import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { UserOutlined, DashboardOutlined, SettingOutlined, FileAddOutlined } from '@ant-design/icons';

const { Sider } = Layout;

const Sidebar = ({ setSelectedKey, collapsed, onCollapse }) => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth={80} // уменьшенная ширина для сжатого состояния
      style={{
        background: '#fff',
        color: '#000',
        boxShadow: '2px 0 6px rgba(0, 21, 41, 0.12)', // Добавлена тень для лучшего контраста
      }}
    >
      <div style={{ padding: '16px', textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>
        Панель управления
      </div>
      <Menu
        theme="light"
        mode="inline"
        onSelect={({ key }) => setSelectedKey(key)}
        style={{
          background: '#ffffff',
          color: '#000',
        }}
      >
        <Menu.Item key="1" icon={<DashboardOutlined />}>
          Главная
        </Menu.Item>
        <Menu.Item key="2" icon={<UserOutlined />}>
          Пользователи
        </Menu.Item>
        <Menu.Item key="3" icon={<SettingOutlined />}>
          Редактировать роли
        </Menu.Item>
        <Menu.Item key="4" icon={<FileAddOutlined />}>
          Новости
        </Menu.Item>
        <Menu.Item key="5" icon={<FileAddOutlined />}>
          Добавить новость
        </Menu.Item>
      </Menu>
      <div style={{ padding: '16px', textAlign: 'center' }}>
        <Button
          type="primary"
          danger
          onClick={handleLogout}
          style={{ width: '100%' }}
        >
          Выход
        </Button>
      </div>
    </Sider>
  );
};

export default Sidebar;