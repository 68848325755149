import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserList from './components/dashboard/UserList';
import Login from './components/Login';
import Register from './components/Register';
import PasswordRecovery from './components/PasswordRecovery';
import Dashboard from './components/Dashboard';
import PrivateRoute from './components/PrivateRoute';
import NewsList from './components/dashboard/NewsList';
import AddNews from './components/dashboard/AddNews';
import EditNews from './components/dashboard/EditNews';
import EditUserRole from './components/dashboard/EditUserRole';
import CourseList from './components/dashboard/CourseList';
import CourseDetails from './components/dashboard/CourseDetails';
import CreateCourse from './components/dashboard/CreateCourse';
import CourseModules from './components/dashboard/CourseModules';
import CreateModule from './components/dashboard/CreateModule';
import CreateTest from './components/dashboard/CreateTest';
import MyCourses from './components/dashboard/MyCourses';
import TestPage from './components/dashboard/TestPage';
import TestResultsPage from './components/dashboard/TestResultsPage';
import TestResults from './components/dashboard/TestResults'; // Импорт компонента для всех результатов
import UploadJournal from './components/dashboard/UploadJournal'; // Импорт с экспортом по умолчанию
import ViewJournals from './components/dashboard/ViewJournals'; // Импорт с экспортом по умолчанию

const App = () => {
  return (
    <Routes>
      {/* Public routes */}
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/recover-password" element={<PasswordRecovery />} />

      {/* Dashboard routes */}
      <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
        <Route path="users" element={<UserList />} />
        <Route path="edit-roles" element={<EditUserRole />} />
        <Route path="news" element={<NewsList />} />
        <Route path="add-news" element={<AddNews />} />
        <Route path="news/edit/:id" element={<EditNews />} />

        <Route path="courses" element={<CourseList />} />
        <Route path="courses/:id" element={<CourseDetails />} />
        <Route path="create-course" element={<CreateCourse />} />

        {/* Маршруты для модулей */}
        <Route path="courses/:id/modules" element={<CourseModules />} />
        <Route path="courses/:id/create-module" element={<CreateModule />} />
        <Route path="courses/:id/modules/:moduleId/create-test" element={<CreateTest />} />

        {/* Мои курсы */}
        <Route path="my-courses/:userId" element={<MyCourses />} />

        {/* Маршруты для тестов и результатов */}
        <Route path="my-courses/:userId/test/:testId" element={<TestPage />} />
        <Route path="my-courses/:userId/test-results/:resultId" element={<TestResultsPage />} />
        <Route path="test-results" element={<TestResults />} />
        <Route path="journals/upload" element={<UploadJournal />} />
        <Route path="journals/view" element={<ViewJournals />} />
      </Route>
    </Routes>
  );
};

export default App;