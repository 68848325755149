import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from 'graphql-tag';
import { Form, Input, Button, Upload, DatePicker, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// GraphQL мутация для добавления новостей
const ADD_NEWS = gql`
  mutation AddNews($title: String!, $lead: String, $body: String, $image: Upload, $publishDate: String) {
    addNews(title: $title, lead: $lead, body: $body, image: $image, publishDate: $publishDate) {
      id
      title
    }
  }
`;

const AddNewsForm = () => {
  const [addNews] = useMutation(ADD_NEWS);
  const [form] = Form.useForm();
  const [file, setFile] = useState(null);
  const [body, setBody] = useState('');
  const [publishDate, setPublishDate] = useState(null);

  const handleFileChange = ({ fileList }) => {
    if (fileList.length > 0) {
      const newFile = fileList[0].originFileObj;
      setFile(newFile);
    } else {
      setFile(null);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const formattedDate = date.toISOString();
      setPublishDate(formattedDate);
    } else {
      setPublishDate(null);
    }
  };

  const handleSubmit = async (values) => {
    const { lead, title } = values;

    const formattedValues = {
      title,
      lead,
      body,
      image: file,
      publishDate: publishDate || undefined,
    };

    try {
      await addNews({ variables: formattedValues });
      message.success('Новость успешно добавлена');
      form.resetFields();
      setFile(null);
      setBody('');
      setPublishDate(null);
    } catch (error) {
      message.error(`Ошибка при добавлении новости: ${error.message}`);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      style={{ maxWidth: 800, margin: '0 auto', padding: '24px', background: '#f9f9f9', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
    >
      <Form.Item
        name="title"
        label="Заголовок"
        rules={[{ required: true, message: 'Пожалуйста, введите заголовок' }]}
      >
        <Input placeholder="Введите заголовок новости" />
      </Form.Item>
      <Form.Item name="lead" label="Лид">
        <Input placeholder="Введите короткий лид для новости" />
      </Form.Item>
      <Form.Item label="Содержимое" required>
        <ReactQuill 
          value={body} 
          onChange={setBody} 
          placeholder="Напишите содержимое новости здесь..." 
          style={{ border: '1px solid #d9d9d9', borderRadius: '4px' }}
        />
      </Form.Item>
      <Form.Item label="Дата публикации">
        <DatePicker 
          showTime 
          onChange={handleDateChange} 
          style={{ width: '100%' }} 
          placeholder="Выберите дату и время публикации" 
        />
      </Form.Item>
      <Form.Item label="Изображение">
        <Upload
          beforeUpload={() => false}
          onChange={handleFileChange}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} style={{ backgroundColor: '#e6f7ff', borderColor: '#91d5ff', color: '#1890ff' }}>
            Загрузить изображение
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddNewsForm;