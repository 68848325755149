import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';
import { gql, useQuery } from '@apollo/client';

// GraphQL-запрос для получения списка пользователей
const GET_USERS = gql`
  query {
    users {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`;

const UserList = () => {
  const { loading, error, data } = useQuery(GET_USERS);
  
  if (loading) return <Spin />;
  if (error) return <p>Ошибка: {error.message}</p>;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Имя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      key: 'roles',
      render: roles => roles.map(role => role.name).join(', '),
    },
  ];

  return (
    <Table dataSource={data.users} columns={columns} rowKey="id" />
  );
};

export default UserList;