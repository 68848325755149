import React from 'react';
import { Layout } from 'antd'; // Добавлен импорт Button


const { Header } = Layout;

const AppHeader = () => {

  const handleLogout = () => {
    // Логика выхода (удалить токен и перенаправить на страницу входа)
    localStorage.removeItem('token');
    window.location.href = '/login';
  };


  return (
    <Header style={{ background: '#f0f2f5', padding: '0 20px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>


      </div>
    </Header>
  );
};

export default AppHeader;