import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Image } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';

const GET_NEWS_BY_ID = gql`
  query getNewsById($id: String!) {
    news(id: $id) {
      id
      title
      lead
      publishDate
      posted
      imageUrl
    }
  }
`;

const UPDATE_NEWS = gql`
  mutation updateNews($id: String!, $title: String!, $lead: String!, $publishDate: String!, $posted: Boolean!, $imageUrl: String) {
    updateNews(id: $id, title: $title, lead: $lead, publishDate: $publishDate, posted: $posted, imageUrl: $imageUrl) {
      id
    }
  }
`;

const EditNews = () => {
  const { id } = useParams(); // Получаем ID новости из параметров маршрута
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_NEWS_BY_ID, {
    variables: { id },
  });
  
  const [updateNews] = useMutation(UPDATE_NEWS);

  const [form] = Form.useForm();

  useEffect(() => {
    if (data && data.news) {
      form.setFieldsValue({
        title: data.news.title,
        lead: data.news.lead,
        publishDate: data.news.publishDate,
        posted: data.news.posted,
        imageUrl: data.news.imageUrl,
      });
    }
  }, [data, form]);

  const handleSubmit = async (values) => {
    try {
      await updateNews({
        variables: {
          id,
          title: values.title,
          lead: values.lead,
          publishDate: values.publishDate,
          posted: values.posted,
          imageUrl: values.imageUrl,
        },
      });
      notification.success({
        message: 'Успешно',
        description: 'Новость успешно обновлена.',
      });
      navigate('/news'); // Перенаправляем на страницу со списком новостей
    } catch (error) {
      console.error('Ошибка при обновлении новости:', error);
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось обновить новость. Попробуйте еще раз.',
      });
    }
  };

  if (loading) return <p>Загрузка...</p>;
  if (error) return <p>Ошибка: {error.message}</p>;

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item name="title" label="Заголовок" rules={[{ required: true, message: 'Пожалуйста, введите заголовок!' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="lead" label="Лид" rules={[{ required: true, message: 'Пожалуйста, введите лид!' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="publishDate" label="Дата публикации" rules={[{ required: true, message: 'Пожалуйста, введите дату публикации!' }]}>
        <Input type="datetime-local" />
      </Form.Item>
      <Form.Item name="posted" label="Опубликовано" valuePropName="checked">
        <Input type="checkbox" />
      </Form.Item>
      <Form.Item name="imageUrl" label="URL изображения">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">Сохранить</Button>
      </Form.Item>
      {data.news.imageUrl && (
        <Form.Item label="Текущее изображение">
          <Image width={100} src={data.news.imageUrl} />
        </Form.Item>
      )}
    </Form>
  );
};

export default EditNews;